import { CheckIcon } from "@heroicons/react/20/solid";
import { Fragment } from "react";

const steps = [
  {
    name: "Planning",
    description:
      "Coming up with marketing, renting servers & domains, deciding next steps.",
    status: "complete",
  },
  {
    name: "Alpha Development",
    description:
      "Designed first version of backend API, iOS application, Python wrapper, and website.",
    status: "complete",
  },
  {
    name: "Alpha Testing",
    description:
      "Integrate the project with a research lab, testing that notifications work on their devices.",
    status: "complete",
  },
  {
    name: "Beta Development",
    description:
      "From the changes made during alpha testing, release to the public.",
    status: "complete",
  },
  {
    name: "Beta Testing",
    description: "Public testing using an only-free tier of the application.",
    status: "current",
  },
  {
    name: "Tier Creation",
    description:
      "After the app is stable, I plan to create a paid tier for users to support the app.",
    status: "upcoming",
  },
  {
    name: "Android Support",
    status: "upcoming",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Progress() {
  return (
    <div className="overflow-hidden bg-white pt-16" id="integrate">
      <div className="mx-auto max-w-7xl md:px-6 lg:px-8">
        <div className="flex flex-col items-center py-4">
          <div className="px-6 lg:px-0 lg:pr-4 lg:pt-4">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Progress
              </p>
            </div>
          </div>
          <div className="sm:px-6 lg:px-0">
            <nav aria-label="Progress" className="p-4 lg:p-8 lg:py-16 pb-8">
              <ol role="list" className="overflow-hidden">
                {steps.map((step, stepIdx) => (
                  <li
                    key={step.name}
                    className={classNames(
                      stepIdx !== steps.length - 1 ? "pb-10" : "",
                      "relative"
                    )}
                  >
                    {step.status === "complete" ? (
                      <>
                        {stepIdx !== steps.length - 1 ? (
                          <div
                            className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-indigo-600"
                            aria-hidden="true"
                          />
                        ) : null}
                        <a className="group relative flex items-center transition">
                          <span className="flex h-9 items-center justify-center">
                            <span className="transition relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-indigo-600 group-hover:bg-indigo-800">
                              <CheckIcon
                                className="h-5 w-5 text-white"
                                aria-hidden="true"
                              />
                            </span>
                          </span>
                          <span className="ml-4 flex min-w-0 flex-col">
                            <span className="text-sm font-medium p-0">
                              {step.name}
                            </span>
                            {step.description ? (
                              <span className="text-sm text-gray-500">
                                {step.description}
                              </span>
                            ) : (
                              ""
                            )}
                          </span>
                        </a>
                      </>
                    ) : step.status === "current" ? (
                      <>
                        {stepIdx !== steps.length - 1 ? (
                          <div
                            className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
                            aria-hidden="true"
                          />
                        ) : null}
                        <a
                          href={step.href}
                          className="group relative flex items-start"
                          aria-current="step"
                        >
                          <span
                            className="flex h-9 items-center"
                            aria-hidden="true"
                          >
                            <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-indigo-600 bg-white">
                              <span className="h-2.5 w-2.5 rounded-full bg-indigo-600" />
                            </span>
                          </span>
                          <span className="ml-4 flex min-w-0 flex-col">
                            <span className="text-sm font-medium text-indigo-600">
                              {step.name}
                            </span>
                            <span className="text-sm text-gray-500">
                              {step.description}
                            </span>
                          </span>
                        </a>
                      </>
                    ) : (
                      <>
                        {stepIdx !== steps.length - 1 ? (
                          <div
                            className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
                            aria-hidden="true"
                          />
                        ) : null}
                        <a
                          href={step.href}
                          className="group relative flex items-start"
                        >
                          <span
                            className="flex h-9 items-center"
                            aria-hidden="true"
                          >
                            <span className="transition relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400">
                              <span className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" />
                            </span>
                          </span>
                          <span className="ml-4 flex min-w-0 flex-col">
                            <span className="text-sm font-medium text-gray-500">
                              {step.name}
                            </span>
                            <span className="text-sm text-gray-500">
                              {step.description}
                            </span>
                          </span>
                        </a>
                      </>
                    )}
                  </li>
                ))}
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}
