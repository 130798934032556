import CodeSnippet from "./code_snippet";
import Hero from "./hero";
import Pricing from "./pricing";
import Progress from "./progress";

export default function Landing() {
  return (
    <div className="pb-8">
      <div class="absolute inset-0 h-full w-full bg-white bg-[radial-gradient(#f0f2f6_2px,transparent_1px)] [background-size:64px_64px] pb-8"></div>
      <Hero />
      <CodeSnippet />
      {/* <Pricing /> */}
      <Progress />
      <div className="w-full py-4 flex flex-row justify-center items-center gap-2 text-gray-600 font-light text-sm">
        <a href="privacy.html">Privacy Policy</a>
        <a href="toc.html">Terms & Conditions</a>
      </div>
    </div>
  );
}
