import { useState } from "react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { ChevronRightIcon } from "@heroicons/react/20/solid";

import logo from "./assets/logo.png";
import Banner from "./banner";

const navigation = [
  { name: "Dashboard", href: "#" },
  { name: "Integrate", href: "#integrate" },
  // { name: "Account Types", href: "#accts" },
];

export default function Hero() {
  return (
    <div>
      <Banner />
      <header className="absolute top-6 inset-x-0 z-50">
        <nav
          className="flex items-center justify-between p-6 lg:px-8"
          aria-label="Global"
        >
          <div className="flex lg:flex-1">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Whenfin</span>
              <img className="h-12 w-auto" src={logo} alt="" />
            </a>
          </div>
        </nav>
      </header>
      <div className="relative isolate pt-14">
        <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:py-40">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:flex-auto">
            <h1 className="mt-10 max-w-lg text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
              Forget your lonnnngg<span className="opacity-80">g</span>
              <span className="opacity-60">g</span>
              <span className="opacity-40">g</span>
              <span className="opacity-20">g</span> projects
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              A few simple lines add notifications to your scripts. Fire and
              forget– they'll let you know when they need you.
            </p>
            <div className="mt-10 flex items-start gap-x-6">
              <div className="flex flex-col gap-2 group">
                {/* <a
                  // href="#"
                  className="transition rounded-md bg-gray-300 px-3.5 py-2.5 text-sm font-semibold text-gray-600 shadow-sm hover:bg-gray-200 hover:cursor-not-allowed hover:text-gray-400"
                >
                  View on the app store
                </a> */}
                <a
                  href="https://apps.apple.com/us/app/whenfin/id6504001973"
                  className="transition rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:ring ring-indigo-600 ring-offset-2"
                >
                  View on the app store
                </a>
                {/* <p className="w-full text-center group-hover:text-gray-500 p-0 text-sm text-white transition">
                  Coming soon!
                </p> */}
              </div>
              <a
                href="#integrate"
                className="text-sm font-semibold leading-6 text-gray-900 py-2"
              >
                See how it works <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
          <div className="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow">
            <svg
              viewBox="0 0 366 729"
              role="img"
              className="mx-auto w-[22.875rem] max-w-full drop-shadow-xl"
            >
              <title>App screenshot</title>
              <defs>
                <clipPath id="2ade4387-9c63-4fc4-b754-10e687a0d332">
                  <rect width={316} height={684} rx={36} />
                </clipPath>
              </defs>
              <path
                fill="#333"
                d="M363.315 64.213C363.315 22.99 341.312 1 300.092 1H66.751C25.53 1 3.528 22.99 3.528 64.213v44.68l-.857.143A2 2 0 0 0 1 111.009v24.611a2 2 0 0 0 1.671 1.973l.95.158a2.26 2.26 0 0 1-.093.236v26.173c.212.1.398.296.541.643l-1.398.233A2 2 0 0 0 1 167.009v47.611a2 2 0 0 0 1.671 1.973l1.368.228c-.139.319-.314.533-.511.653v16.637c.221.104.414.313.56.689l-1.417.236A2 2 0 0 0 1 237.009v47.611a2 2 0 0 0 1.671 1.973l1.347.225c-.135.294-.302.493-.49.607v377.681c0 41.213 22 63.208 63.223 63.208h95.074c.947-.504 2.717-.843 4.745-.843l.141.001h.194l.086-.001 33.704.005c1.849.043 3.442.37 4.323.838h95.074c41.222 0 63.223-21.999 63.223-63.212v-394.63c-.259-.275-.48-.796-.63-1.47l-.011-.133 1.655-.276A2 2 0 0 0 366 266.62v-77.611a2 2 0 0 0-1.671-1.973l-1.712-.285c.148-.839.396-1.491.698-1.811V64.213Z"
              />
              <path
                fill="#111"
                d="M16 59c0-23.748 19.252-43 43-43h246c23.748 0 43 19.252 43 43v615c0 23.196-18.804 42-42 42H58c-23.196 0-42-18.804-42-42V59Z"
              />
              <foreignObject
                width={316}
                height={684}
                transform="translate(24 24)"
                clipPath="url(#2ade4387-9c63-4fc4-b754-10e687a0d332)"
              >
                <img src="/mobile-app-screenshot.png" alt="" />
              </foreignObject>
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}
