export default function CodeSnippet() {
  return (
    <div className="overflow-hidden bg-white" id="integrate">
      <div className="mx-auto max-w-7xl md:px-6 lg:px-8">
        <div className="grid grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-start">
          <div className="px-6 lg:px-0 lg:pr-4 lg:pt-4">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Integrate quickly
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                With only a few lines of code, your program can send
                notifications straight to your mobile device.
              </p>
            </div>
          </div>
          <div className="sm:px-6 lg:px-0">
            <div className="relative isolate overflow-hidden bg-slate-800 px-3 py-4 sm:mx-auto sm:max-w-2xl sm:rounded-3xl sm:pl-12 sm:pr-0 sm:py-12 lg:mx-0 lg:max-w-none font-mono text-gray-300 shadow-md">
              <h2 className="py-1">
                <span className="text-slate-600 select-none">00 </span>
                <span className="text-violet-500">from</span>{" "}
                <span className="text-red-700">whenfin</span>{" "}
                <span className="text-violet-500">import</span>{" "}
                <span className="text-red-700">Whenfin</span>
              </h2>
              <h2 className="py-1">
                <span className="text-slate-600 select-none">01 </span>
                <span className="text-red-700">Whenfin</span>.
                <span className="text-orange-700">connect</span>
                <span className="text-yellow-500">(</span>
                <span className="text-emerald-400">"user"</span>,{" "}
                <span className="text-emerald-400">"project"</span>,{" "}
                <span className="text-emerald-400">"secret"</span>
                <span className="text-yellow-500">)</span>
              </h2>
              <h2 className="py-1 text-slate-600 select-none">
                &nbsp;&nbsp;&nbsp;...
              </h2>
              <h2 className="py-1">
                <span className="text-slate-600 select-none">14 </span>
                <span className="text-red-700">Whenfin</span>.
                <span className="text-orange-700">send</span>
                <span className="text-yellow-500">(</span>
                <span className="text-emerald-400">"Success"</span>,{" "}
                <span className="text-emerald-400">"Training finished."</span>
                <span className="text-yellow-500">)</span>
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
